import React from 'react';
import { Layout, Menu } from 'antd';
import LOGO from '../../assets/images/logo.svg';
import { setAuth } from '../../features/user.slice';
import { useDispatch } from 'react-redux';
// import ResponsiveAntMenu from 'responsive-ant-menu'

const { Header } = Layout;

const HeaderWeb = () => {

    const dispatch = useDispatch()

    const logout = () => {
        dispatch(setAuth({}))
    }


    const pagesLink = [
        {
            name: 'Home',
            link: '/home'
        },
        {
            name: 'Who are we?',
            link: '/whoarewe'
        },
        {
            name: 'Services',
            link: '/services'
        },
        {
            name: 'Contact Us',
            link: '/contact'
        },
        {
            name: "Logout",
            onClick: () => logout()
        }
    ]
    return (

        <Header className='d-flex justify-content-between align-items-center bg-white py-5'>
            <img src={LOGO} alt="" className='img-fluid' />
            {/* <ResponsiveAntMenu
                activeLinkKey={location.pathname}
                mobileMenuContent={isMenuShown => isMenuShown ? <button>Close</button> : <button>Open</button>}
                menuClassName={'responsive-ant-menu'}
            >
                {(onLinkClick) =>
                    <Menu>
                        <Menu.Item key='/' className={'menu-home'}>
                            <a onClick={onLinkClick} href={'/#'}>Home</a>
                        </Menu.Item>
                        <Menu.Item key='/#foo'>
                            <a onClick={onLinkClick} href={'/#foo'}>Foo</a>
                        </Menu.Item>
                        <Menu.Item key='/#bar'>
                            <a onClick={onLinkClick} href={'/#bar'}>Bar</a>
                        </Menu.Item>
                    </Menu>
                }
            </ResponsiveAntMenu> */}
            <Menu
                className='border-0'
                mode="horizontal"
                defaultSelectedKeys={['']}
            >
                {
                    pagesLink.map((pageLink) => {
                        return (
                            <Menu.Item key={pageLink.name} onClick={pageLink.onClick}>
                                <a href={pageLink.link} className='h6 fw-bold text-decoration-none'>{pageLink.name}</a>
                            </Menu.Item>
                        )
                    })
                }
            </Menu>
        </Header>
    )
}

export default HeaderWeb;
