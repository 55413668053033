export const nameSplitter = (item,upto) => {
    if (item.length > upto) return item.slice(0, upto) + "...";
    return item;
  };
  
  
  export const itemExistArr = (id,list) => {
      const ids = list?.map((item) => item.id);

      if (ids &&  ids.length > 0){
        return ids.includes(id);
      }
  
      return false;
    };


        //this will return you the data  acc to day.
    export const getGroupedDaysAvailability = (data) => {
      const getAllAvailableDays = Array.from(new Set(data.map((e)=>e.day)));
      let result = [];
      getAllAvailableDays.forEach((e)=>{
        result.push({
          [e]: data.filter((el)=>el.day == e)
        })
      }) 
      return result;
    }
    export function setLocalStorageItem(key, value) {
      try {
        localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        console.error("Error setting localStorage item:", error);
      }
    }
    
    // Get data from localStorage
    export function getLocalStorageItem(key) {
      try {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        console.error("Error getting localStorage item:", error);
        return null;
      }
    }
    
    // Remove data from localStorage
    export function removeLocalStorageItem(key) {
      try {
        localStorage.removeItem(key);
      } catch (error) {
        console.error("Error removing localStorage item:", error);
      }
    }


    
    
    
    
    

