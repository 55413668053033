import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Divider, Row, Col, Progress, Avatar, List, Typography } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { UsecustomTherapist } from "./therapist.logic";
import AppointmentBook from "../../components/AppointmentBook";
import reviewStar from '../../assets/images/review-star.svg'
import { useDispatch, useSelector } from 'react-redux'

import "./styles.scss";
import AppLoader from "../../common/Loader/Loader";
import jwt_decode from "jwt-decode";


export default function TherapistDetail() {
  const params = useParams();


  const userType = useSelector(item => item.userSlice);

  const decode  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}

  const [data, setData, loading] = UsecustomTherapist(
    "therapist/single-therapist",
  { id: params.id }
  );

if (!data.result) {
  return;
}

  if(loading){
  return <AppLoader />
}




const { result } = data;

const { name, email, gender, address, phoneNumber, experience, speciality } = result;

return (
  <>
    <Row justify='space-around my-5'>
      <Col span={14}>
        <Row>
          <Col span={8}>
            <Avatar size={170} icon={<UserOutlined />} />
          </Col>
          <Col span={12} className="d-flex flex-column align-items-start justify-content-center">
            <h4 className="text-capitalize">{name}</h4>
            <h6>{speciality || "-"}</h6>
            <div className="d-flex justify-content-between w-100">
              <p className="pe-md-2">{experience || "-"} <br /> Year of Experience</p>
              <Divider type="vertical" className="display-6 border-dark-subtle border-2" />
              <p className="h6 ps-md-2">98%  <br /> Satisfaction Rate</p>
            </div>
          </Col>
        </Row>
        <Divider className="display-6 border-dark-subtle border-2" />
        <Row>
          <Col span={24}>
            <div className="d-flex justify-content-start align-items-center mb-2">
              <Avatar shape="square" size={24} src={<img src={reviewStar} />} />
              <h4 className="text-capitalize ms-2 mb-0">{name}'s Reviews</h4>
            </div>
          </Col>
          <Col span={10} className="d-flex justify-content-start align-items-center mt-md-2">
            <Avatar style={{ backgroundColor: '#612F8A' }} size={80}>98%</Avatar>
            <h6 className="ms-2">Satisfied out of <br /> 30 Patients</h6>
          </Col>
          <Col span={14} className="mt-md-2">
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="w-100">Doctor Check up</h6>
              <Progress percent={96} strokeColor='#8ACCA5' />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="w-100">Environment</h6>
              <Progress percent={98} strokeColor='#8ACCA5' />
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="w-100">Behaviour</h6>
              <Progress percent={97} strokeColor='#8ACCA5' />
            </div>
          </Col>
        </Row>
        <Divider className="display-6 border-dark-subtle border-2" />
        <Row>
          <Col span={24}>
            <h4>Services</h4>
            <List className='listStyling ms-3'>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">{speciality}</Typography.Text>
              </List.Item>
            </List>
          </Col>
        </Row>
        <Divider className="display-6 border-dark-subtle border-2" />
        <Row>
          <Col span={24}>
            <h4>Education</h4>
            <List className='listStyling ms-3'>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">BS Psychology, Bahria University, (graduation year)</Typography.Text>
              </List.Item>
            </List>
          </Col>
        </Row>
        <Divider className="display-6 border-dark-subtle border-2" />
        <Row className="pb-4">
          <Col span={24}>
            <h4>Areas of Expertise</h4>
            <List className='listStyling ms-3'>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Attention Span</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Focus</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Behaviour Modification (Biting/Hitting)</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Sitting Tolerance ADLs</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Socialisation</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Anger Management</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Requesting, Command Following</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Task Achievement</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Developmental Disorders (ASD, ADHD, IDD, GDD)</Typography.Text>
              </List.Item>
              <List.Item className='border-0 p-0'>
                <Typography.Text className="h6">Emotional Regulation</Typography.Text>
              </List.Item>
            </List>
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <AppointmentBook result={result} />
      </Col>
    </Row>
  </>
);
}
