import React, { useEffect, useState } from "react";
import { UsecustomTherapist } from "./therapist.logic";
import TherapistSingle from "./TherapistSingle";
import { Layout, Row, Col } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import AppLoader from "../../common/Loader/Loader";
import "./styles.scss";



const { Search } = Input;


export default function Therapist() {
  let [data, setData, loading] = UsecustomTherapist("therapist/list-therapists");
  const [search, setSearch] = useState("")
  const [filtered, setFiltered] = useState([])


  if (loading) {
    return <>  <AppLoader /> </>;
  }


  const onSearch = (e) => {
    setSearch(e.target.value)
  };


  return (
    <div className='parentContainer'>
      <div className="d-flex justify-content-center"> 
      <Search
        placeholder="Search..."
        className="searchField w-50"
        onChange={onSearch}
      />
      </div> 
      <Row justify="center" className='m-0 mt-3' gutter={16}>
        {data?.result?.filter(item => item.name.includes(search)).length > 0 ?
          data?.result?.filter(item => item.name.includes(search)).map(item =>
            <Col span={7}>
              <TherapistSingle item={item} />
            </Col>
          )
          :
          <div className="d-flex justify-content-center w-100"> 
          <p> No data found</p>
          </div>
        }
      </Row>
    </div>
  );
}
