import React from 'react'
import { SocialIcon } from 'react-social-icons';
import { Layout, Row, Col, Button, Input, Space } from 'antd';
import LOGO from '../../assets/images/logo.svg';


const { TextArea } = Input;
const { Footer } = Layout;

const FooterWeb = () => {
    return (

        <Footer style={{ backgroundColor: '#FAF9FF' }}>
            <h1 className='display-5 headertext text-center fw-bold'>Have More Questions? Contact Us</h1>
            <Row className='my-3' justify='space-between'>
                <Col span={24} md={8}>
                    <img src={LOGO} alt="" style={{ width: '128px' }} />
                    <Space direction="vertical" className='w-100'>
                        <Input placeholder="Name" className='bg-transparent shadow-none border-0 border-bottom rounded-0 dark-border' />
                        <Input placeholder="Email Address" className='bg-transparent shadow-none border-0 border-bottom rounded-0 dark-border' />
                        <Input placeholder="Phone Number" className='bg-transparent shadow-none border-0 border-bottom rounded-0 dark-border' />
                        <Input placeholder="Phone Number" className='bg-transparent shadow-none border-0 border-bottom rounded-0 dark-border' />
                        <TextArea rows={3} placeholder="Message" maxLength={100} style={{ resize: 'none', }} className='bg-transparent shadow-none border-0 border-bottom rounded-0 dark-border' />
                    </Space>
                </Col>
                <Col span={24} md={3}>
                    <div>
                        <h6 className='primary-text'>Location:</h6>
                        <h6>Karachi, Pakistan</h6>
                    </div>
                    <div className='mt-md-3'>
                        <h6 className='primary-text'>Email Address:</h6>
                        <h6>Diffables@gmail.com</h6>
                    </div>
                    <div className='mt-md-3'>
                        <h6 className='primary-text'>Contact Number:</h6>
                        <h6>03212799603</h6>
                    </div>
                    <div className='mt-md-3'>
                        <h6 className='primary-text'>Social Media</h6>
                        <div className='social-icon-wrpr'>
                            <SocialIcon className='me-2' url="#" network="facebook" />
                            <SocialIcon className='me-2' url="#" network="twitter" />
                            <SocialIcon className='me-2' url="#" network="instagram" />
                            <SocialIcon className='me-2' url="#" network="linkedin" />
                        </div>
                    </div>
                </Col>
            </Row>
        </Footer>
    )
}

export default FooterWeb