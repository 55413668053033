import React, { useEffect, useState } from 'react'
import { AutoComplete,Select, Calendar  } from 'antd';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import DateAndTimePicker from './DateAndTimePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import moment from "moment";
import { Button,Space } from 'antd';
import { toast } from 'react-toastify';
import jwt_decode from "jwt-decode";


function BookAppointment() {
    const location = useLocation();
    const [options, setOptions] = useState(location.state.children.map(item => {
        return {label : item.name , value: item.name}
    } ));
    const [anotherOptions, setAnotherOptions] = useState([]);
    const [appointment,setData] = useState()
    const [availableTime, setAvailableTime]= useState([]);
    const [children, setChildren] =  useState('')

    const userType = useSelector(item => item.userSlice);
    const [selectedTime, setSelectedTime] = useState('');
    let days = ["Sunday", "Monday", "Tuesday", "Wednesday","Thursday", "Friday","Saturday"]
   
    let url = process.env.REACT_APP_BASE_URL;


    const getData = async() => {




        // const activeTherapist = localStorage.getItem("activeTherapist");
        const activeTherapist = userType.activeTherapist;



        const id = activeTherapist.split("/")[2];

        try {

                    let url = process.env.REACT_APP_BASE_URL;

        const res = await axios.post(`${url}/slots/getAvailableSlots`, {
            therapistId: id
        });
  
        setData(res.data.data)
  
  
        }catch(e){
            console.log("exc",e)
        }
  
  
    }


    useEffect(()=>{

        getData()


    },[])



    console.log("respon",appointment)

    const onChange = (data) => {
      setChildren(data)
    };

    console.log('child', children)
      const [value, setValue] = useState(() => dayjs(new Date()));
      const [selectedValue, setSelectedValue] = useState(() => dayjs(new Date()));
    
      const onSelect = (newValue) => {
        setSelectedValue(newValue);
        console.log("new value",newValue)



       const  date = new Date(newValue);

       const selectedDate = moment(`${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`).format('MM/DD/YYYY');
       setAvailableTime(appointment.filter(item =>  item.date == selectedDate));
       setSelectedTime('')


      };
    console.log('available', availableTime)
      const onPanelChange = (newValue) => {
        setValue(newValue);
      };
    
      const onSearch = (value) => {
        console.log('search:', value);
      };
    

   const _renderAvailableTiming = () => {
      return (
        <Space wrap>
          {availableTime.map(e=><Button type={selectedTime == `${e.startTime}-${e.endTime}` ? 'default':'primary'} disabled={!e.availability} onClick={()=>setSelectedTime(`${e.startTime}-${e.endTime}`)}>
        {e.startTime} - {e.endTime}
</Button>)}
        </Space>
      )
   } 
   
   const bookHandler = async () => {


    const activeTherapist = userType.activeTherapist;
    // const activeTherapist = localStorage.getItem("activeTherapist");

    const id = activeTherapist.split("/")[2];
    // const parentId = jwt_decode(JSON.parse(localStorage.getItem('token')).token) ?? {}
    const decode  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}

    try{
      const result = await axios.post(`${url}/slots/createAppointments`,{
        startTime: selectedTime.split("-")[0],
        endTime:  selectedTime.split("-")[1],
        therapistId: id,
        parentId:decode.id,
        day:availableTime[0].day,
        date:availableTime[0].date,
        children:children
      })
      if(result.data.result._id){
        //toast

        getData()
        
        toast.success("Successfully added!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
    
       
      }
    }catch(e){
      console.log("exc",e)
    }
   }

  return (
    <div>
      <h1> book appointment </h1>
      <Select
    showSearch
    placeholder="Select a person"
    optionFilterProp="children"
    onChange={onChange}
    onSearch={onSearch}
    filterOption={(input, option) =>
      (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    }
    options={options}/>

      <Calendar value={value} onSelect={onSelect} onPanelChange={onPanelChange} />
      <div style={{marginTop: 10}}>

      {availableTime.length > 0 ? _renderAvailableTiming():<>No slots available.</>}
      </div>
      <div style={{marginTop:10}}>

      <Button type='primary' onClick={bookHandler}>
        Book an Appointment
      </Button>
      </div>
    </div>
  )
}

export default BookAppointment
