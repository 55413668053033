import React from "react";
import { Card, Divider, Button } from "antd";
import { Link } from "react-router-dom";
import "./styles.scss";

import therapisTwo from '../../assets/images/therapistwo.png'

  export default  ({ item }) => {
  return (
    <Link to={`${item._id}`} className='text-decoration-none'>
      <Card
        className="primary-boxshadow min-card-height my-2"
        hoverable
    >


        <div className='d-flex flex-column justify-content-center align-items-center'>
          <img src={therapisTwo} className='therapist-img' />
          <h5 className='text-center my-md-2'> {item?.name || "-"}</h5>
          <h6 className='text-center mb-md-3'> {item.speciality || "-"}</h6>
          <div className="d-flex justify-content-between w-100 px-md-3">
            <p className="pe-md-2"> {item.experience || "-"} <br /> Year of Experience</p>
            <Divider type="vertical" className="display-6 border-dark-subtle border-2"/>
            <p className="h6 ps-md-2">98%  <br /> Satisfaction Rate</p>
          </div>
          <Button type="primary" size='large' className='btn btn-primary text-color small lh-1'>Book Appointment</Button>
        </div>
      </Card>
    </Link>
  );
}

