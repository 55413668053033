import React, { useEffect, useState } from 'react'
import { Table, Tag, Space, Button, Card } from 'antd'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import Column from 'antd/es/table/Column'
import { Link, useNavigate } from 'react-router-dom';

import {useLocation} from 'react-router-dom';
import axios from 'axios';
import jwt_decode from "jwt-decode";
import { useSelector } from 'react-redux';


export default function ClientAppointments({parentId}) {

  const [appointment,setData] = useState([])
  const [loader,setLoader] = useState(false)

  const location = useLocation();
  const navigate = useNavigate()
  const userType = useSelector(item => item.userSlice);



  useEffect(()=>{

    async function getData() {
      try {

        setLoader(true)
        let url = process.env.REACT_APP_BASE_URL;




        const decoded  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}     

          const res = await axios.post(`${url}/parents/parent-appointments`, {
              parentId: decoded.id
          });

          if(res && res.data){

            setLoader(false)

            res.data.data.map(item => item["item"]["name"] = decoded.name)
            setData(res.data.data)
          }

      } catch (e) {
          console.log("exception", e)
          setLoader(false)

      }


  }
  getData()

  },[])


  const data = appointment.map((item,index) => {
    return {
      
        key: index,
        name: item?.findTherapist?.name || "-",
        therapyType: item.findTherapist?.speciality || "-",
        appointmentDate: item.item.date,
        appointmentTime: `${item.item.startTime}-${item.item.endTime}` || "-",
        status: 'accepted',
        childrens: item.item.children || "-"
      
    }
  })


const gotoDetail = (record) => {

   navigate("/appointment-detail", {state: record})
 
}



  return (
    <>
      <Card>
        <Table dataSource={data} loading={loader}>
          <Column title="Therapist's Name" dataIndex="name" key="name" />
          <Column title="Child's Name" dataIndex="childrens" key="childrens" />
          <Column title="Therapy Type" dataIndex="therapyType" key="therapyType" />
          <Column title="Date" dataIndex="appointmentDate" key="appointmentDate" />
          <Column title="Time" dataIndex="appointmentTime" key="appointmentTime" />
          <Column
            title="Status"
            dataIndex="status"
            key="status"
            render={(status) => (
              <>
                {
                  status == 'done' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' color="success" icon={<CheckCircleOutlined />} key={status}>
                    {status}
                  </Tag> : status == 'pending' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<ExclamationCircleOutlined />} color="warning" key={status}>
                    {status}

                    </Tag> : status == 'accepted' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<ExclamationCircleOutlined />} color="success" key={status}>
                    {status}

                  </Tag> : status == 'rejected' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<CloseCircleOutlined />} color="error" key={status}>
                    {status}
                  </Tag> : ''
                }

              </>
            )}
          />
          <Column
            title="Action"
            key="action"
            render={(_, record) => (
              <Space size="middle">
                
                  <Button type="primary" className="btn btn-primary small rounded-2 lh-1" onClick={() => gotoDetail(record)}>View Detail</Button>
                
              </Space>
            )}
          />
        </Table>
      </Card>
    </>
  )
}
