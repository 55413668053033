import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../features/user.slice';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

const LoginModal = ({ visible, setVisible }) => {


    const userSlice = useSelector(item => item.userSlice);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    const { loggedIn } = userSlice;



    const handleClose = async (response) => {
        setVisible(false);
        toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        if (response.token) {
            // book appointment page
            navigate("/user")
        }

    };

    const handleFormSubmit = async (values) => {

        setLoader(true)
        try {

            const baseUrl = process.env.REACT_APP_BASE_URL;

            const res = await axios.post(`${baseUrl}/parents/login-parent`, values);

            if (res && res.data) {
                dispatch(setAuth(res.data.token));
                setLoader(false)

                handleClose(res.data)
            }
        } catch (error) {
            setLoader(false)

            console.error("Error logging in:", error);
            const msg = error?.response?.data?.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

        // handleClose();
    };

    return (
        <div>


            <Modal
                title="Login"
                // visible={visible}
                visible={false}
                onCancel={handleClose}
                footer={null}
            >
                <Formik
                    validationSchema={validationSchema} // Add this line

                    initialValues={{
                        email: '',
                        password: '',
                    }}
                    onSubmit={handleFormSubmit}
                >
                    {({ handleSubmit, handleChange }) => (
                        <Form onFinish={handleSubmit}>
                            <Field
                                style={{
                                    marginTop: 10
                                }}
                                name="email"
                                component={Input}
                                prefix={<UserOutlined />}
                                placeholder="Email"
                                onChange={handleChange("email")}
                            />
                            <ErrorMessage name="email" component="div" style={{ color: "red" }} />
                            <Field
                                style={{
                                    marginTop: 10
                                }}
                                name="password"
                                component={Input.Password}
                                prefix={<LockOutlined />}
                                placeholder="Password"
                                onChange={handleChange("password")} />
                            <ErrorMessage name="password" component="div" style={{ color: "red" }} />
                            <Button style={{
                                marginTop: 10
                            }}
                                type="primary" htmlType="submit" block
                                loading={loader}

                            >
                                Login
                            </Button>
                            <p>Or login with Google:</p>
                            <Button
                                style={{
                                    marginTop: 10
                                }}
                                type="default"
                                icon={<GoogleOutlined />}
                                block
                                onClick={() => setVisible(false)}
                            >
                                Login with Google
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </div>
    );
};

export default LoginModal;
