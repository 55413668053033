import React, { useEffect, useState } from "react";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const UsecustomTherapist = (requestUrl,body) => {
  const [therapist, setTherapist] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true)
    axios
      .post(`${BASE_URL}/${requestUrl}`,{
        ...body
      })
      .then((response) => {
        setTherapist(response.data);
        setTimeout(()=>{
          setLoading(false);
        },1000)
      
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  }, []);

  return [therapist, setTherapist, loading];
};
