import { Card, Col, Row, Tag } from 'antd'
import React from 'react'
import { useLocation } from 'react-router'
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    ExclamationCircleOutlined
  } from '@ant-design/icons';

export default function AppointmentDetail() {


    const location = useLocation();


    const {state} = location

    if(!state){
        return
    }

    return (
        <>
            <Card>
                <Row gutter={[24, 48]}>
                    <Col span={8}>
                        <h5>Doctor/Client Name:</h5>
                        <h6>{state.name}</h6>
                    </Col>
                    <Col span={8}>
                        <h5>Date:</h5>
                        <h6>{state.appointmentDate}</h6>
                    </Col>
                    <Col span={8}>
                        <h5>Time:</h5>
                        <h6>{state.appointmentTime}</h6>
                    </Col>
                    <Col span={8}>
                        <h5>Therapy Type:</h5>
                        <h6>{state.therapyType}</h6>
                    </Col>
                    <Col span={8}>
                        <h5>status:</h5>
                        {
                  state.status == 'done' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' color="success" icon={<CheckCircleOutlined />} key={state.status}>
                    {state.status}
                  </Tag> : state.status == 'pending' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<ExclamationCircleOutlined />} color="warning" key={state.status}>
                    {state.status}

                    </Tag> : state.status == 'accepted' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<ExclamationCircleOutlined />} color="success" key={state.status}>
                    {state.status}

                  </Tag> : state.status == 'rejected' ? <Tag className='d-flex justify-content-start align-items-center w-fit-content text-capitalize fw-bold' icon={<CloseCircleOutlined />} color="error" key={state.status}>
                    {state.status}
                  </Tag> : ''
                }
                    </Col>
                    <Col span={24}>
                        <h5>Session Result:</h5>
                        <h6>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Deleniti modi ratione est non aliquid tenetur laborum omnis facere iste. Voluptate fugit qui architecto quo alias quas quidem quibusdam sapiente numquam.</h6>
                    </Col>
                </Row>
            </Card >
        </>
    )
}
