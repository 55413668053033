import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import therapistSlice from './../features/therapist.slice';
import userSlice from '../features/user.slice';
import {combineReducers} from "redux"; 
import { persistReducer, FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER, } from 'redux-persist'
import storage from 'redux-persist/lib/storage'


const reducers = combineReducers({
      therapistSlice: therapistSlice,
      userSlice: userSlice   
  });
  
  const persistConfig = {
      key: 'root',
      storage,
      middleware: getDefaultMiddleware({
         serializableCheck: {
           ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
         },
       }),
      
  };

  const persistedReducer = persistReducer(persistConfig, reducers);


export default configureStore({
   reducer: persistedReducer,
})

