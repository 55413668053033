// Child.js
import React, { useState } from 'react';
import { Avatar, Button, Card, Col, Popconfirm, Space } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';
import UpdateChild from '../../components/Modal/updateChild';

const { Meta } = Card;

const Child = ({ child, parent }) => {

    const [loader, setLoader] = useState(false)
    const [visible, setVisible] = useState(false);

    const [selectedChild, setSelectedChild] = useState({})


    const updateChild = (child) => {
        setVisible(true);
        const childData = {
            ...child,
            parentId: parent[0]._id
        }
        setSelectedChild(childData)
    }



    const deleteChild = () => {

        setLoader(true)
        async function deleter() {

            try {
                const payload = {
                    parentId: parent[0]._id,
                    name: child.name
                }


                let url = process.env.REACT_APP_BASE_URL;


                const res = await axios.post(`${url}/parents/delete-children`, payload);

                if (res && res.data.result) {
                    setLoader(false)

                    toast.success(res.data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });



                }

            } catch (e) {
                setLoader(false)

                toast.success("failed to delete", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });


            }

        }

        deleter()

    }


    const confirm = (e) => {
        console.log(e);
       deleteChild()
      };
      
      const cancel = (e) => {
        console.log(e);
      };


    return (

        <>

            <UpdateChild setVisible={setVisible} visible={visible} selectedChild={selectedChild} />
            <Col span={8}>
                <Card className='primary-boxshadow'>
                    {child.name != '' ? <Avatar
                        style={{
                            backgroundColor: '#f56a00',
                            verticalAlign: 'middle',
                        }}
                        size="large"
                        className='mb-2'
                    >
                        {child.name}
                    </Avatar> : ''}

                    <Meta title={child.name} />
                    <p>Age: {child.age}</p>
                    <p>Gender: {child.gender}</p>
                    <p>School: {child.school}</p>
                    <p>Symptops: {child.symptoms}</p>
                    <Space size="middle">
                        <Button type="primary" className="btn btn-primary small lh-1 rounded-2 text-capitalize" onClick={() => updateChild(child)}> edit </Button>

                        <Popconfirm
    title="Delete"
    description="Are you sure to delete this child?"
    onConfirm={confirm}
    onCancel={cancel}
    okText="Yes"
    cancelText="No"
  >
                        <Button type="secondary" className="btn btn-secondary small lh-1 rounded-2 text-capitalize"  loading={loader}> delete </Button>
  </Popconfirm>
                    </Space>
                </Card>
            </Col>

        </>
    );
};

export default Child;