import React, { useState } from 'react'
import { Layout, Row, Col, Typography, Image, Button, List, Card, Avatar, Spin } from 'antd';
import HeaderWeb from './Headerweb';
import FooterWeb from './Footerweb';
import Testmonials from './Testmonials';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

import landingImg from '../../assets/images/landingImg.svg'
import sectioncup from '../../assets/images/section-cup.svg'
import childMental from '../../assets/images/child-mental.png'
import Unleash from '../../assets/images/unleash.png'
import ourObjThree from '../../assets/images/ourobjthree.svg'
import ourObjFour from '../../assets/images/ourobjfour.svg'
import ourObjFive from '../../assets/images/ourobjfive.svg'
import therapistOne from '../../assets/images/therapistone.png'
import therapisTwo from '../../assets/images/therapistwo.png'
import therapisThree from '../../assets/images/therapisthree.png'
import ourStory from '../../assets/images/ourStory.png'
import partnerOne from '../../assets/images/partnersoch.png'
import partnerTwo from '../../assets/images/partner-verkpro.png'
import pressOne from '../../assets/images/pressone.png'
import pressTwo from '../../assets/images/presstwo.png'
import pressThree from '../../assets/images/pressthree.png'
import secTwoBg from '../../assets/images/sectwobg1920.jpg'
import nextSec from '../../assets/images/nextsectionbg.jpg'
import './styles.scss';
import 'swiper/css/bundle';
import '@smastrom/react-rating/style.css'
import { useNavigate } from 'react-router';
import { LoadingOutlined } from '@ant-design/icons';

import cloudBg from '../../assets/images/awardsbg.svg'

const { Content } = Layout;


const WebHomePage = () => {


    const navigate = useNavigate()


    const goToTherapist = () => {

        navigate("/therapist")
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 34,
                color: "white",
                marginLeft: "10px"
            }}

            spin
        />
    );

    return (
        <>
            <Layout>
                <HeaderWeb />
                <Content>
                    <Row justify="center" className='align-items-center' style={{ backgroundColor: '#CACAFE' }} >
                        <Col span={24} md={8} className='d-flex flex-column justify-content-center align-items-center order-md-1 order-2'>
                            <h1 className='display-3 headertext text-center fw-bold'>Connect With The Right Therapist!</h1>
                            <Button type="primary" size='large' className='btn btn-primary text-color w-auto h-auto p-3 mt-3' onClick={goToTherapist}>Book a Therapist  </Button>
                        </Col>
                        <Col span={24} md={12} className='order-md-2 order-1'>
                            <Image
                                preview={false}
                                src={landingImg}
                                className='img-fluid'
                            />
                        </Col>
                    </Row>
                    <Row justify="space-around" className='backgroundPrimary align-items-center py-3'>
                        <Col span={12} md={3}>
                            <h2 className='display-5 text-center text-white fw-bold'>600+</h2>
                            <h4 className='text-center text-white fw-normal'>Appointments <br /> Booked</h4>
                        </Col>
                        <Col span={12} md={3}>
                            <h2 className='display-5 text-center text-white fw-bold'>4+</h2>
                            <h4 className='text-center text-white fw-normal'>Countries <br /> Served</h4>
                        </Col>
                        <Col span={12} md={3}>
                            <h2 className='display-5 text-center text-white fw-bold'>6+</h2>
                            <h4 className='text-center text-white fw-normal'>Schools <br /> On Board</h4>
                        </Col>
                        <Col span={12} md={3}>
                            <h2 className='display-5 text-center text-white fw-bold'>23+</h2>
                            <h4 className='text-center text-white fw-normal'>Therapists <br /> On Panel</h4>
                        </Col>
                    </Row>
                    <Row className='position-relative'>
                        <img src={secTwoBg} className='position-absolute w-100 h-100' style={{ objectFit: 'fill' }} />
                        <Col span={24} className='py-md-5'>
                            <Row className='py-md-5'>
                                <h1 className='display-5 headertext text-center fw-bold text-center d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Awards & Achievements</h1>
                                <Col span={24} justify="center" >
                                    <Row justify="center" align='center' >
                                        <Col span={24} md={8}>
                                            <Image
                                                preview={false}
                                                src={sectioncup}
                                                className='img-fluid'
                                            />
                                        </Col>
                                        <Col span={24} md={8} className='d-flex align-items-center'>
                                            <List className='award-list'>
                                                <List.Item className='border-0'>
                                                    <Typography.Text className='fw-bold h4'>Generated Seed funding of <span className='primary-color h3 fw-bold'>1.2 million PKR</span> from Women Building Cohesive Society by Arts Council Pakistan.</Typography.Text>
                                                </List.Item>
                                                <List.Item className='border-0'>
                                                    <Typography.Text className='fw-bold h4'>Won <span className='primary-color h3 fw-bold'>250,000</span> PKR at Spur Startup Grant Challenge by Metrics.</Typography.Text>
                                                </List.Item>
                                                <List.Item className='border-0'>
                                                    <Typography.Text className='fw-bold h4'>Among <span className='primary-color h3 fw-bold'>Top 25</span> Startups to be shortlisted for Standard Charter Women In Tech. (Cohort 4).</Typography.Text>
                                                </List.Item>
                                            </List>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='py-md-5'>
                                <h1 className='display-5 headertext text-center fw-bold text-center d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Our Objectives</h1>
                                <Col span={24}>
                                    <Row className='mb-4' justify='center' align='stretch'>
                                        <Col span={22} md={6} className='gutter-row my-md-0 my-3'>
                                            <Card className='h-100 primary-boxshadow min-card-height'>
                                                <Avatar src={childMental} alt='' size={64} style={{ display: 'flex', textAlign: 'center', margin: 'auto' }} />
                                                <h5 className='text-center'>Create awareness & acceptance for Child Mental Health  & Neurodevelopmental disorders</h5>
                                            </Card>
                                        </Col>
                                        <Col span={22} md={6} className='gutter-row my-md-0 my-3'>
                                            <Card className='h-100 primary-boxshadow w-100 min-card-height'>
                                                <Avatar src={Unleash} alt='' size={64} style={{ display: 'flex', textAlign: 'center', margin: 'auto' }} />
                                                <h5 className='text-center'>Help children unleash their unique learning pattern</h5>
                                            </Card>
                                        </Col>
                                        <Col span={22} md={6} className='gutter-row my-md-0 my-3'>
                                            <Card className='h-100 primary-boxshadow min-card-height'>
                                                <Avatar src={ourObjThree} alt='' size={64} style={{ display: 'flex', textAlign: 'center', margin: 'auto' }} />
                                                <h5 className='text-center'>Create awareness & acceptance for Child Mental Health  & Neurodevelopmental disorders</h5>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row justify='center' align='stretch' className='m-0 mt-xl-4 mb-3'>
                                        <Col span={22} md={6} className='gutter-row pe-md-2 my-md-0 my-3'>
                                            <Card className='h-100 primary-boxshadow min-card-height'>
                                                <Avatar src={ourObjFour} alt='' size={64} style={{ display: 'flex', textAlign: 'center', margin: 'auto' }} />
                                                <h5 className='text-center'>Provide relevant resources to aid their learning process</h5>
                                            </Card>
                                        </Col>
                                        <Col span={22} md={6} className='gutter-row my-md-0 my-3'>
                                            <Card className='h-100 primary-boxshadow min-card-height'>
                                                <Avatar src={ourObjFive} alt='' size={64} style={{ display: 'flex', textAlign: 'center', margin: 'auto' }} />
                                                <h5 className='text-center'>SDG Goal 3; mental health and well-being & SDG Goal 4; inclusive and equitable quality education </h5>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Testmonials />
                    <Row style={{ backgroundImage: `url(${nextSec})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        <Col span={24}>
                            <Row className='my-md-5' justify='center'>
                                <h1 className='display-5 headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Therapists On-board</h1>
                                <Col span={24} md={20}>
                                    <Swiper
                                        className='p-md-3'
                                        modules={[Navigation]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        navigation
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <Card className='min-card-height therapistCard primary-boxshadow'>
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <img src={therapistOne} className='therapist-img' />
                                                    <h4 className='text-center my-md-2'>Poona Sadhwani</h4>
                                                    <h5 className='text-center mb-md-3'>Occupational Therapist</h5>
                                                    <h5 className='text-center mb-md-3'>Karachi 
                                                    | Online</h5>
                                                    <Button type="primary" size='large' className='btn btn-primary text-color small lh-1'>Consult Now</Button>
                                                </div>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='min-card-height therapistCard primary-boxshadow'>
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <img src={therapisTwo} className='therapist-img' />
                                                    <h4 className='text-center my-md-2'>Rutba Damani</h4>
                                                    <h5 className='text-center mb-md-3'>Registered Behaviour Technician</h5>
                                                    <h5 className='text-center mb-md-3'>United Kingdom 
                                                    | Online</h5>
                                                    <Button type="primary" size='large' className='btn btn-primary text-color small lh-1'>Consult Now</Button>
                                                </div>
                                            </Card>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <Card className='min-card-height therapistCard primary-boxshadow'>
                                                <div className='d-flex flex-column justify-content-center align-items-center'>
                                                    <img src={therapisThree} className='therapist-img' />
                                                    <h4 className='text-center my-md-2'>Nimra Sajjad</h4>
                                                    <h5 className='text-center mb-md-3'>Clinical Psychologist</h5>
                                                    <h5 className='text-center mb-md-3'>United Kingdom 
                                                    | Online</h5>
                                                    <Button type="primary" size='large' className='btn btn-primary text-color small lh-1'>Consult Now</Button>
                                                </div>
                                            </Card>
                                        </SwiperSlide>
                                    </Swiper>
                                </Col>
                            </Row>
                            <Row className='my-md-5'>
                                <h1 className='display-5 headertext text-center fw-bold text-center d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Our Story</h1>
                                <Col span={24} justify="center">
                                    <Row justify="center" align='center' >
                                        <Col span={24} md={8}>
                                            <Image
                                                preview={false}
                                                src={ourStory}
                                                className='img-fluid'
                                            />
                                        </Col>
                                        <Col span={24} md={8} className='d-flex align-items-center'>
                                            <Typography.Text className='fw-bold h5'>It all started with the story of a slow learner child who couldn't fit in the mainstream school environment. Despite being diagnosed with Attention Deficit Disorder (ADD), a neurological disorder that causes a range of behaviour problems, one of the parents still refused to accept the existence of this learning disorder in his child.</Typography.Text>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='my-md-5' justify='center'>
                                <Col span={24} justify='center' className='d-flex'>
                                    <h1 className='display-5 headertext text-center fw-bold text-center d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Partners & Collaborations</h1>
                                </Col>
                                <Col span={24} md={12}>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={50}
                                        slidesPerView={2}
                                        navigation
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img src={partnerOne} className='img-fluid' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img src={partnerTwo} className='img-fluid' />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </Col>
                            </Row>
                            <Row className='my-md-5' justify='center'>
                                <Col span={24} justify='center' className='d-flex'>
                                    <h1 className='display-5 headertext text-center fw-bold text-center d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})` }}>Press & Publication</h1>
                                </Col>
                                <Col span={24} md={12}>
                                    <Swiper
                                        modules={[Navigation]}
                                        spaceBetween={50}
                                        slidesPerView={3}
                                        navigation
                                        onSlideChange={() => console.log('slide change')}
                                        onSwiper={(swiper) => console.log(swiper)}
                                    >
                                        <SwiperSlide>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img src={pressOne} className='img-fluid' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>

                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img src={pressTwo} className='img-fluid' />
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img src={pressThree} className='img-fluid' />
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Content>
                <FooterWeb />
            </Layout>
        </>
    )
}
export default WebHomePage;