import React, { useState } from 'react'
import { Button, Card, Row, Typography } from 'antd';
import Child from './Child';
import AppointmentBook from '../../components/AppointmentBook';
import { useNavigate } from 'react-router';
import UpdateChild from '../../components/Modal/updateChild';
import AddChild from '../../components/Modal/AddChild';


const Parent = ({ data }) => {

    const navigate = useNavigate()

    const [addChildVisible, setAddVisible] = useState(false);


    const children = data && data[0]?.children?.map((child, index) => (
        <Child key={index} child={child} parent={data} />
    ));


    // const getAppointments = () => {
    //     navigate("/client-appointments", { state: { id: data[0]?._id, name: data[0]?.name } })
    // }

    const addChild = () => {
        setAddVisible(true)
    }


    if (!data[0]) {
        return null
    }


    return (
        <div>

            <AddChild visible={addChildVisible} setVisible={setAddVisible} parentId={data[0]?._id} />

            <Card title={data[0].name.toUpperCase()} style={{ margin: '20px' }}>
                <div className="d-flex justify-content-between">
                    <Typography>Email: {data[0].email}</Typography>
                    {/* <Button type="primary" className="btn btn-primary small rounded-2 lh-1" onClick={getAppointments}>
                        Appointments
                    </Button> */}
                    <Button type="primary" className="btn btn-primary small rounded-2 lh-1" onClick={addChild}>
                        Add
                    </Button>
                </div>
                <Typography.Title level={3}>Children:</Typography.Title>
                <Row gutter={[16, 24]}>
                    {children}
                </Row>
            </Card>
        </div>
    );
};


export default Parent;



