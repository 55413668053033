import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Card, Col, Divider, List, Row } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './ProfileView.css';
import AppLoader from '../../common/Loader/Loader';
import { Link } from 'react-router-dom';
import jwt_decode from "jwt-decode";


function Profile() {

  const userSlice = useSelector(item => item.userSlice);
  const userType = useSelector(item => item.userSlice);



  const { loggedIn } = userSlice;
  const [visible, setVisible] = useState(loggedIn);

  const [profileData, setData] = useState({})
  const [loader, setLoader] = useState({})

  const bookOnline = () => {
    setVisible(true)
  }


  useEffect(() => {

    const decode  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}

    async function getData() {
      setLoader(true)
      try {

        const baseUrl = process.env.REACT_APP_BASE_URL;

        const res = await axios.post(`${baseUrl}/therapist/single-therapist`, {
          id: decode.id
        });

        setLoader(false)

        setData(res.data.result)
      } catch (e) {
        console.log("exception", e)
        setLoader(false)

      }

    }

    getData()

  }, [])


  if (!profileData) {
    return null
  }

  if (loader) {
    return <AppLoader />
  }

  console.log("profile data", profileData)


  let resultAvailable = {};

  profileData?.availability?.forEach((element, index) => {

    const section = element.day;

    if (!resultAvailable[section]) {
      resultAvailable[section] = []
    }

    resultAvailable[section].push(element)

  })



  return (
    <div>
      <Card className="profile-card">
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row justify='space-between' align='middle'>
              <Col span={12}>
                <h6 className='h5 fw-bold'>Profile Info</h6>
              </Col>
              <Col span={12} className='d-flex justify-content-end align-items-center'>
                <Button type="primary" className="btn btn-primary small rounded-2 lh-1">Edit</Button>
                <Button type="primary" className="btn btn-primary small rounded-2 lh-1 ms-2">
                  <Link to='/therapist-appointments' className='text-decoration-none'>Appointments</Link>
                </Button>
              </Col>
            </Row>
          </Col>
          <Divider className='my-2' />
          <Col span={4}>
            <Avatar className='backgroundSecondary' size={120} icon={<UserOutlined />} />
          </Col>
          <Col span={10}>
            <h2 className='primary-text text-capitalize'>{profileData.name}</h2>
            <h6><span className='primary-text'>Email:</span><span className='ms-2'>{profileData.email}</span></h6>
            <h6><span className='primary-text'>Address:</span><span className='ms-2'>{profileData.address}</span></h6>
            <h6><span className='primary-text'>Phone Number:</span><span className='ms-2'>{profileData.phoneNumber}</span></h6>
            <h6><span className='primary-text'>Region:</span><span className='ms-2'>{profileData.region}</span></h6>

          </Col>

          <Col span={10} style={{marginTop: "50px"}}> 
            <h6><span className='primary-text'>Gender:</span><span className='ms-2'>{profileData.gender}</span></h6>
            <h6><span className='primary-text'>Years of Experience:</span><span className='ms-2'>6 years</span></h6>
            <h6><span className='primary-text'>Speciality:</span><span className='ms-2'>{profileData.speciality}</span></h6>

          </Col>


        </Row>


        <Row gutter={[16]}>
          <Col span={24}>
            <h2 className='primary-text mb-3'>Days:</h2>
            <div className="d-flex justify-content-start align-items-center">
              {Object.keys(resultAvailable).map(item => <Badge className='backgroundPrimary rounded-2 p-2 text-white m-2'>{item}</Badge>
              )}
            </div>
            <Divider />
            <h2 className='primary-text'>Availability:</h2>
            <div className='d-flex' style={{ flexDirection: "column" }}>
              {Object.keys(resultAvailable).map(item => {
                const values = resultAvailable[item]
                const valuesArr = values.map(item => <div style={{ padding: "12px", marginLeft: "5px" }}>
                  <Badge  >
                    <ClockCircleOutlined
                      style={{
                        color: '#8435c5',
                      }}
                    />

                    <span className='m-2 pe-2'>
                      {item.startTime}-{item.endTime}
                    </span>
                  </Badge>  </div>)

                return (
                  <>

                    <div>
                    <Badge className='backgroundSecondary rounded-2 p-2 text-white m-2'> {item} </Badge>

                      <div className='d-flex'>
                        {valuesArr}
                      </div>
                    </div>
                  </>
                )
              })}
            </div>
          </Col>
        </Row>


      </Card>
    </div>
  )
}

export default Profile
