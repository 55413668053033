import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";


const initialStateValue = {
    loading: false,
    products: [],
    errors: "",
    cart: [],
    total: 0,
    wishlist: [],
    loggedIn: false,
    auth : {},
    activeTherapist: ""
};

export const fetchProducts = createAsyncThunk("products/fetch", async () => {
    const response = await fetch("https://fakestoreapi.com/products");
    const data = await response.json();
    return data;
});




export const userSlice = createSlice({
    name: "userSlice",
    initialState: initialStateValue,
    reducers: {

        setAuth: (state,action) => {
            // state.auth = action.payload
            state.auth = action.payload
        },

        isLoggedIn: (state, action) => {

            state.loggedIn = action.payload;
        },

        setActiveTherapist: (state,action) => {
            state.activeTherapist = action.payload
        }

    },

    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state) => {
            state.loading = true;
        });

        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.loading = false;
            state.products = action.payload;
            state.errors = "";
        });

        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.loading = false;
            state.products = [];
            state.errors = action.error.message;
        });
    },
});

export const { isLoggedIn,setAuth,setActiveTherapist } = userSlice.actions;

export default userSlice.reducer;
