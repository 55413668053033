import React from 'react'
import { Row, Col, Card } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar } from 'swiper/modules';
import { Rating } from '@smastrom/react-rating';

import doodleBg from '../../assets/images/doodlebg.svg'
import cloudBg from '../../assets/images/awardsbg.svg'

const Testmonials = () =>{
    return (
        <Row className='py-xl-5' justify='center' style={{ backgroundImage: `url(${doodleBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
            <h1 className='display-5 headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Testimonials</h1>
            <Col span={24} md={20}>
                <Swiper
                    className='p-md-3'
                    modules={[Navigation]}
                    spaceBetween={20}
                    navigation
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        320: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 3,
                        },
                    }}
                >
                    <SwiperSlide>
                        <Card className='h-100 primary-boxshadow min-card-height'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Rating
                                    style={{ maxWidth: 100 }}
                                    value={5}
                                    readOnly
                                />
                                <h5 className='text-center'>“My experience has been amazing, this platform and its experts have helped my child a lot!”</h5>
                                <h6>Nida Ali - UAE</h6>
                            </div>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card className='h-100 primary-boxshadow min-card-height'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Rating
                                    style={{ maxWidth: 100 }}
                                    value={5}
                                    readOnly
                                />
                                <h5 className='text-center'>Great team of professionals and easy to use platform. Keep it up.</h5>
                                <h6>Asad Sheikh - PK</h6>
                            </div>
                        </Card>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Card className='h-100 primary-boxshadow min-card-height'>
                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Rating
                                    style={{ maxWidth: 100 }}
                                    value={5}
                                    readOnly
                                />
                                <h5 className='text-center'>All therapists gathered on one platform made booking appointments so much easier for me!</h5>
                                <h6>Sadiya Tariq - UK</h6>
                            </div>
                        </Card>
                    </SwiperSlide>
                </Swiper>
            </Col>
        </Row>
    )
}

export default Testmonials;
