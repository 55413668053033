import React, { useState } from 'react';
import { Modal, Button, Input, Card, Row, Col, Radio } from 'antd';
import { UserOutlined, LockOutlined, GoogleOutlined } from '@ant-design/icons';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setAuth } from '../../features/user.slice';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import HeaderWeb from '../Webpages/Headerweb';
import FooterWeb from '../Webpages/Footerweb';

import LOGO from '../../assets/images/logo.svg';
import doodleBg from '../../assets/images/doodlebg.svg'

const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Email is required'),
    password: Yup.string().required('Password is required'),
});

export default function LoginPage() {

    const userSlice = useSelector(item => item.userSlice);
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(false)
    const [type, setType] = useState("Parent")
    const navigate = useNavigate()


    const { loggedIn } = userSlice;

    const handleChangeType = (e) => {
        setType(e)
    }

    const handleClose = async (response) => {
        toast.success(response.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });

        if (response.token) {
            // book appointment page
            navigate("/user")
        }

    };

    const handleFormSubmit = async (values) => {


        let url = process.env.REACT_APP_BASE_URL;

        if (type === "Parent") {
            url = `${url}/parents/login-parent`
        } else {
            url = `${url}/therapist/login-therapist`
        }

        setLoader(true)
        try {


            const res = await axios.post(url, values);

            if (res && res.data) {
                dispatch(setAuth(res.data.token));
                setLoader(false)

                toast.success(res.data.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // localStorage.setItem("token", JSON.stringify(res.data))
                dispatch(setAuth(res.data))

                if (res.data.token) {

                    
                    const activeTherapist = userSlice.activeTherapist;

                    (type === "Parent" && activeTherapist)
                        ? navigate(`${activeTherapist}`)
                        : (type === "Parent")
                            ? navigate("/user")
                            : navigate("/therapist-profile");
                }

            }
        } catch (error) {
            setLoader(false)

            console.error("Error logging in:", error);
            const msg = error?.response?.data?.message
            toast.error(msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    }

    return (
        <>
            <HeaderWeb />
            <Row justify='center' className='py-5' style={{ backgroundImage: `url(${doodleBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                <Col span={7}>
                    <Card className='shadow'>
                        <Row gutter={16} justify='center'>
                            <img src={LOGO} alt="" className='img-fluid' />
                            <h4 className='text-primary w-auto m-auto my-3'>Please Login to access appointments</h4>
                        </Row>
                        <Formik
                            validationSchema={validationSchema} // Add this line

                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            onSubmit={handleFormSubmit}
                        >
                            {({ handleSubmit, handleChange }) => (
                                <Form onFinish={handleSubmit}>
                                    <Field
                                        name="email"
                                        className='h6 border-primary mt-2'
                                        component={Input}
                                        prefix={<UserOutlined />}
                                        placeholder="Email"
                                        onChange={handleChange("email")}
                                    />
                                    <ErrorMessage name="email" component="div" className='text-danger' />
                                    <Field
                                        name="password"
                                        className='h6 border-primary mt-2'
                                        component={Input.Password}
                                        prefix={<LockOutlined />}
                                        placeholder="Password"
                                        onChange={handleChange("password")} />
                                    <ErrorMessage name="password" component="div" className='text-danger' />
                                    <div className='d-flex align-items-center justify-content-evenly '>
                                    
                                    <Radio.Group onChange={(e) => handleChangeType(e.target.value)} value={type}>
      <Radio value={"Parent"}>Parent</Radio>
      <Radio value={"Therapist"}>Therapist</Radio>

    </Radio.Group>
                                 

                                    </div>
                                    <Row justify='center' gutter={[4,4]} className='mt-2'>
                                        <Col span={12}>
                                            <Button 
                                                className='btn small lh-1 btn-primary h-auto py-2'
                                                type="primary" htmlType="submit" block
                                                loading={loader}
                                            >
                                                Login
                                            </Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button
                                                className='btn small btn-secondary lh-1 h-auto d-flex align-items-center py-2'
                                                type="default"
                                                icon={<GoogleOutlined />}
                                                block
                                            >
                                                Login with Google
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </Col>
            </Row>
            <FooterWeb />
        </>
    )

}
