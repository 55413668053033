import { Link, Outlet } from "react-router-dom";
import { Col, Row } from "antd";
import { useEffect, useState } from "react";

import { Layout, Menu, Button, theme } from 'antd';

import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from '@ant-design/icons';
import HeaderWeb from "../../pages/Webpages/Headerweb";
import { getLocalStorageItem } from "../../utils";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";

const { Header, Sider, Content } = Layout;

const PageLayout = () => {

  // const userType = getLocalStorageItem("token");

  const userType = useSelector(item => item.userSlice);

  const decode  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}

  const [collapsed, setCollapsed] = useState(false);

  console.log("userType",userType)

  const pagesLink = [
    {
      name: 'Profile',
      link: 'user',
      key: "parent-profile"
    },

    {
      name: 'Profile',
      link: 'therapist-profile',
      key: "therapist-profile"
    },

    {
      name: 'Appointments',
      link: 'therapist-appointments',
      key: 'therapist-appointments'
    },
    {
      name: 'Therapist',
      link: 'therapist',
      key: 'therapist-list'
    },
    {
      name: 'Client Appointments',
      link: 'client-appointments',
      key: 'client-appointments'
    },
  ]
  return (
    <>
      <Row>
        <Col span={24}>
          <HeaderWeb />
        </Col>
      </Row>
      <Row className="min-vh-100">

      {userType.auth.token ? 
             <><Col span={3}>
            <Layout className="w-100 h-100">
              <Sider trigger={null} collapsible collapsed={collapsed} className="pt-3">
                <Menu
                  theme="dark"
                  mode="inline"
                >{pagesLink.map((pageLink) => {

                  if (decode.type === "therapist" && (pageLink.key === "client-appointments" || pageLink.key === "parent-profile" || pageLink.key === "therapist-list" )) {
                    return;
                  } else if (decode.type === "parent" && (pageLink.key === "therapist-appointments" || pageLink.key === "therapist-profile")) {
                    return;
                  }

                  return (
                    <Menu.Item key={pageLink.name}>
                      <Link to={pageLink.link} className='h6 fw-bold text-decoration-none'>
                        {pageLink.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
                </Menu>
              </Sider>
            </Layout>
          </Col><Col span={21} className="p-3 bg-body-secondary">
              <Outlet style />
              {/* <ContentSection type={sideBarState.currentTab} /> */}
            </Col></>
    
                :
<Col span={24} className="p-3 bg-body-secondary">
              <Outlet style />
              {/* <ContentSection type={sideBarState.currentTab} /> */}
            </Col>

  }

 
      </Row>
    </>
  );
};

export default PageLayout;
