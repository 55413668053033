import { Card, Col, Row } from 'antd'
import React from 'react'

function Reviews() {
  return (
    <>
        <Row>
            <Col span={12}><h5>Reviews (30)</h5></Col>
            <Col span={12}><h6 className='text-end'><a href='#'>Read All</a></h6></Col>
        </Row>
        <Row>
            <Col span={24}>
                <Card className="appointmentCard shadow-none mb-4">
                    <h6>“Was quite satisfied with the service. She was very polite and listened carefully”</h6>
                    <h6>Verified Patient: A*** K***   -   6 Months Ago</h6>
                </Card>
                <Card className="appointmentCard shadow-none mb-4">
                    <h6>“Good Experience”</h6>
                    <h6>Verified Patient: B**** A**   -   2 Months Ago</h6>
                </Card>
                <Card className="appointmentCard shadow-none mb-4">
                    <h6>“She was very helpful and polite, amazing experience”</h6>
                    <h6>Verified Patient: K**** M****   -    2 Weeks Ago</h6>
                </Card>
            </Col>
        </Row>
    </>
  )
}

export default Reviews;