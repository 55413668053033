import React, { Fragment, useContext, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import App from "../App";
import PageLayout from "../layout/parentLayout";
import Therapist from "../pages/TherapistWrapper/Therapist";
import TherapistDetail from "../pages/TherapistWrapper/TherapistDetail";
import WebHomePage from '../pages/Webpages/WebHome'
import TherapistAppointments from "../pages/TherapistWrapper/TherapistAppointments";
import ClientAppointments from "../pages/TherapistWrapper/ClientAppointments";
import AppointmentDetail from "../pages/TherapistWrapper/AppointmentDetail";
import User from "../pages/User/user";
import Login from "../pages/Home/LoginPage";
import Services from "../pages/Webpages/Services";
import Profile from "../components/Therapist/Profile";
import LoginPage from "../pages/Home/LoginPage";
import BookAppointment from "../pages/User/BookAppointment";
import { getLocalStorageItem } from "../utils";
import jwt_decode from "jwt-decode";
import { useSelector } from "react-redux";


const RouterDefault = () => {


  const userType = useSelector(item => item.userSlice);


  return (
    <Router>
      <Fragment>
        <Routes>

        <Route exact path="/" element={<WebHomePage />} />

          <Route exact path="/" element={<PageLayout />}>
            {/* <Route exact path="/" element={<App />} /> */}

            <Route exact path="/user" element={<User />} />
            <Route exact path="/therapist" element={<Therapist />} />
            <Route exact path="/therapist/:id" element={<TherapistDetail />} />
            <Route exact path="/appointment-detail" element={<AppointmentDetail />} />
            <Route exact path="/therapist-appointments" element={<TherapistAppointments />} />
            <Route exact path="/client-appointments" element={<ClientAppointments />} />
            <Route exact path="/therapist-profile" element={<Profile />} />
            <Route exact path="/book-appointment" element={<BookAppointment />} />
          </Route>
          <Route exact path="/home" element={<WebHomePage />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/therapist" element={<Therapist />} />
          {/* <Route exact path="/login" element={<LoginPage /> }  /> */}
          <Route
            exact
            path="/login"
            element={
              userType.auth.token ? (

                
                <Navigate to="/home" /> // Redirect to the user page if already logged in
              ) : (
                <LoginPage />
              )
            }
          />
        </Routes>
      </Fragment>
    </Router>
  );
};

export default RouterDefault;


