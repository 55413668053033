import React, { useEffect, useState } from "react";
import { Alert, Card, Row, Col, Divider, TimePicker, Modal, Collapse } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import { Button, Space } from "antd";
import Reviews from "./Reviews";
import LoginModal from "./Modal/Login";
import { getGroupedDaysAvailability } from "../utils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setActiveTherapist } from "../features/user.slice";
import { useHistory ,useLocation } from 'react-router-dom';
import axios from "axios";
import { useSelector } from 'react-redux';
import jwt_decode from "jwt-decode";

function AppointmentBook({ result }) {


  const baseUrl = process.env.REACT_APP_BASE_URL;

  const userSlice = useSelector(item => item.userSlice);
  const userType = useSelector(item => item.userSlice);


  const { loggedIn } = userSlice;

  const dispatch = useDispatch();
  const navigate = useNavigate()
  const location = useLocation()


  const [slot, setSelectedSlot] = useState("");
  const [visible, setVisible] = useState(loggedIn);
  const [availability, setAvailability] = useState([]);



  useEffect(()=>{
    let resultAvailable = [];
    result.availability && getGroupedDaysAvailability(result.availability).forEach((element,index)=>{
      let time = ``;
      let key = Object.keys(element)[0];
      if(element[key].length > 0)
        element[key].forEach((e)=>{time += ` ${e.startTime} - ${e.endTime} , `})
      if(element[key].length == 0)
        time= '<p> Not Available <p>'
        resultAvailable.push({
          key: index,
          label:key,
          children:time
        })
    })
    setAvailability(resultAvailable);
    
  },[])
  if (!result) {
    return;
  }


  const bookOnline = async() => {


    setVisible(true)
    if(userType.auth.token){



      const decode  =  userType.auth.token ? jwt_decode(userType.auth.token) : {}



      try {
        const res = await axios.post(`${baseUrl}/parents/get-children`, {
          parentId: decode.id
        });

        const childs = res.data.data[0].children;

        if(childs.length === 0){
          navigate("/user")
        } else {
          
          dispatch(setActiveTherapist(location.pathname))
          // localStorage.setItem("activeTherapist",location.pathname)

          // navigate("/book-appointment")
          navigate("/book-appointment", { state: {children : childs} })
        }

        // setData(res.data)
    } catch (e) {
        console.log("exception", e)
    }

      // navigate("/bo0k-appointment")
    }else {
      dispatch(setActiveTherapist(location.pathname))
      // localStorage.setItem("activeTherapist",location.pathname)
      navigate("/login")
    }

    // navigate("/login")
    // setVisible(true)



  }

  const selectedSlot = (timeslot) => {
    setSelectedSlot(timeslot);
  };








  const slotDetail = () => {
    return (
      <>
        <p>
          {" "}
          Youll be alotted an time of 1 hr with your therapist starting from{" "}
          {slot}{" "}
        </p>
        <p>rules and regulations applied</p>
      </>
    );
  };

  return (
    <>

      <LoginModal visible={visible} setVisible={setVisible} />


      <Card className="appointmentCard mb-4">
        <Row justify='space-between'>
          <Col span={12}><h5>Fee:</h5></Col>
          <Col span={12}><h5 className="text-end">Rs.2000</h5></Col>
        </Row>
        <Divider className="display-6 border-dark-subtle border-2 my-3" />
        <Row justify='space-between'>
          <Col span={24} className="d-flex align-items-center">
            <ClockCircleOutlined style={{ fontSize: '16px', color: '#09893D' }} />
            <h5 className="ms-2 mb-0" style={{ color: '#09893D' }}>Availability</h5>
          </Col>
        </Row>
        <Row justify='space-between' align='flex-start' className="my-md-3 my-2">

          <Collapse items={availability} />

        </Row>
        <Row className="mt-3">
          <Col span={24} className="d-flex justify-content-center">
            <Button type="primary" className="btn btn-secondary lh-1 h-auto p-3 w-100" onClick={bookOnline}>Book Online Session</Button>
          </Col>
          <Col span={24} className="d-flex justify-content-center mt-3">
            <Button type="primary" className="btn btn-primary lh-1 h-auto p-3 w-100">Book In-Person Session</Button>
          </Col>
        </Row>

      </Card>
      <Reviews />
    </>
  );
}

export default AppointmentBook;
