import React, { useState } from 'react'
import FooterWeb from './Footerweb'
import HeaderWeb from './Headerweb'
import { Card, Col, Layout, Radio, Row } from 'antd'
import { Content } from 'antd/es/layout/layout'

import behavior from '../../assets/images/behaviourtherapy.svg';
import occupational from '../../assets/images/occupationaltherapy.svg';
import remedial from '../../assets/images/remedialtherapy.svg';
import speech from '../../assets/images/speechtherapy.svg';
import clinical from '../../assets/images/clinicalpsychologist.svg';
import child from '../../assets/images/childpsychiatrist.svg';

import doodleBg from '../../assets/images/doodlebg.svg'
import cloudBg from '../../assets/images/awardsbg.svg'

export default function Services() {

    const [status, setStatus] = useState()

    const radioHandler = (status) => {
        setStatus(status);
    };

    return (
        <>
            <Layout>
                <HeaderWeb />
                <Content>
                    <Row className='py-5' style={{ backgroundColor: '#FAF9FF' }} >
                        <Col span={24}>
                            <h4 className='display-6 headertext text-center fw-bold mb-md-4 mb-2'>Services</h4>
                        </Col>
                        <Col span={24} className='d-flex justify-content-center'>
                            <Radio.Group defaultValue="behavior" className='px-3'>
                                <Row className='mb-5' gutter={[24, 24]} align='stretch' justify='center'>
                                    <Col span={8}>
                                        <Radio.Button className='h-100 border-0 rounded-2 shadow p-0 w-100' value="behavior" onClick={() => radioHandler('behavior')}>
                                            <Card className='rounded-2 h-100'>
                                                <Row gutter={24} justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={behavior} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Behaviour Therapy</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>
                                    </Col>
                                    <Col span={8}>
                                        <Radio.Button className='h-100 border-0 rounded-2 shadow p-0 w-100' value="occupational" onClick={() => radioHandler('occupational')}>
                                            <Card className='rounded-2 h-100'>
                                                <Row justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={occupational} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Occupational Therapy</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>

                                    </Col>
                                    <Col span={8}>
                                        <Radio.Button className='h-auto border-0 rounded-2 shadow p-0 w-100' value="occupational" onClick={() => radioHandler('remedial')}>
                                            <Card className='rounded-2 w-100'>
                                                <Row justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={remedial} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Remedial Therapy</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>

                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} align='stretch' justify='center'>
                                    <Col span={8}>
                                        <Radio.Button className='h-100 border-0 rounded-2 shadow p-0 w-100' value="speech" onClick={() => radioHandler('speech')}>
                                            <Card className='rounded-2 h-100'>
                                                <Row justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={speech} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Speech Therapy</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>
                                    </Col>
                                    <Col span={8}>
                                        <Radio.Button className='h-100 border-0 rounded-2 shadow p-0 w-100' value="clinical" onClick={() => radioHandler('clinical')}>
                                            <Card className='rounded-2 h-100'>
                                                <Row justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={clinical} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Clinical Psychologist</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>
                                    </Col>
                                    <Col span={8}>
                                        <Radio.Button className='h-100 border-0 rounded-2 shadow p-0 w-100' value="child" onClick={() => radioHandler('child')}>
                                            <Card className='rounded-2 h-100'>
                                                <Row justify='center' align='middle'>
                                                    <Col span={8}>
                                                        <img className='img-fluid' src={child} />
                                                    </Col>
                                                    <Col span={12}>
                                                        <h4 className='text-center m-0'>Child Psychiatrist</h4>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </Radio.Button>

                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row justify='center' align='middle' className='py-md-5 py-3' style={{ backgroundImage: `url(${doodleBg})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
                        {
                            (status === 'behavior' || status === '')  &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Behaviour Therapy</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>Behavior therapy is a psychological approach aiming to understand and modify human behavior by employing evidence-based techniques for positive change. It focuses on addressing maladaptive behaviors and psychological difficulties. This therapy is commonly used to treat conditions like:</h6>
                                    <ul className='h6'>
                                        <li>Anxiety disorders</li>
                                        <li>Phobias</li>
                                        <li>OCD</li>
                                        <li>ADHD</li>
                                        <li>ASD</li>
                                    </ul>
                                    <h6>and can be applied in individual or group therapy sessions as well as part of broader treatment approaches.</h6>

                                </Card>
                            </Col>
                        }
                        {
                            status === 'occupational' &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Occupational Therapy</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>Occupational therapy is a healthcare profession focused on enabling individuals of all ages to engage in meaningful activities that are essential to them. The primary goal is to enhance independence and improve overall quality of life. Occupational therapists work with people facing physical, cognitive, developmental, or emotional challenges that affect their daily tasks.</h6>

                                    <h6>They use various techniques to achieve desired outcomes, including:</h6>
                                    <ul className='h6'>
                                        <li>Activity analysis</li>
                                        <li>Adaptive equipment and assistive technology</li>
                                        <li>Environmental modifications</li>
                                        <li>Skill development</li>
                                        <li>Sensory integration</li>
                                        <li>Cognitive rehabilitation</li>
                                    </ul>
                                </Card>
                            </Col>
                        }
                        {
                            status === 'remedial' &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Remedial Therapy</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>Remedial therapy in education is targeted interventions and support provided to students experiencing difficulties in specific academic areas, aiming to help them catch up to their peers and bridge knowledge gaps. It focuses on subjects like reading, writing, math, and language skills, provided to students significantly behind their grade level. Key aspects include:</h6>
                                    <ul className='h6'>
                                        <li>Identification through assessments</li>
                                        <li>Individualized instruction</li>
                                        <li>Targeted strategies</li>
                                        <li>Skill development</li>
                                        <li>Progress monitoring</li>
                                        <li>Collaborative approach</li>
                                    </ul>
                                    <h6>The ultimate goal is to provide support that enables struggling students to succeed academically and close the achievement gap.</h6>
                                </Card>
                            </Col>
                        }
                        {
                            status === 'speech' &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Speech Therapy</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>Speech and language therapy is a healthcare profession focusing on evaluating, diagnosing, and treating communication disorders and difficulties. The main goal is to improve individuals' communication skills and address challenges in expression and comprehension. The therapy involves comprehensive assessments and utilizes techniques like:</h6>
                                    <ul className='h6'>
                                        <li>Articulation therapy</li>
                                        <li>Language intervention</li>
                                        <li>Voice therapy</li>
                                        <li>Fluency therapy</li>
                                        <li>AAC implementation</li>
                                        <li>Swallowing therapy </li>
                                    </ul>
                                    <h6>The ultimate aim is to help individuals overcome their communication barriers and improve their overall quality of life..</h6>
                                </Card>
                            </Col>
                        }
                        {
                            status === 'clinical' &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Clinical Psychologist</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>A clinical psychologist is a mental health specialist who focuses on assessing, diagnosing, and treating psychological disorders and mental health issues. They use various therapeutic techniques to help individuals, couples, families, and groups improve their emotional well-being. Clinical psychologists conduct psychological assessments using tests and interviews to diagnose mental health conditions and formulate treatment plans.</h6>

                                    <h6>They offer therapy or psychotherapy to people of all ages, employing evidence-based approaches like cognitive-behavioral therapy, psychodynamic therapy, and interpersonal therapy. Working collaboratively with clients, they address emotional distress, psychological difficulties, and strive to enhance overall mental well-being.</h6>
                                </Card>
                            </Col>
                        }
                        {
                            status === 'child' &&
                            <Col span={16}>
                                <div className='text-center'>
                                    <h1 className='headertext text-center fw-bold d-inline-block w-auto cloud-bg mx-auto my-md-4 my-2' style={{ backgroundImage: `url(${cloudBg})`, }}>Child Psychiatrist</h1>
                                </div>
                                <Card className='rounded-2 shadow-sm'>
                                    <h6>A child psychiatrist is a medical professional specializing in the mental health and well-being of children and adolescents. Their role involves evaluating, diagnosing, and treating a range of emotional, behavioral, and developmental issues that young individuals may face. They work closely with children, parents, and other caregivers to understand and address any psychological challenges. </h6>


                                    <h6>Their ultimate aim is to promote the mental and emotional growth of young patients, help them cope with difficulties, and support their overall well-being. By providing personalized treatment plans, including therapy, counseling, and, when necessary, medication, child psychiatrists strive to create a positive and nurturing environment for children to flourish and achieve their fullest potential.</h6>
                                </Card>
                            </Col>
                        }
                    </Row>
                </Content>
                <FooterWeb />
            </Layout>
        </>
    )
}
