import React, { useState } from 'react';
import { Button, Modal, Form, Input, Select } from 'antd';
import axios from 'axios';
import { toast } from 'react-toastify';

const { Option } = Select;

function AddChild({visible,setVisible,parentId}) {


  const handleCancel = () => {
    setVisible(false);
  };

  const onFinish = async (values) => {

    try {


      const baseUrl = process.env.REACT_APP_BASE_URL;

        const res = await axios.post(`${baseUrl}/parents/update-children`, {
            ...values
        });

        if(res && res.data){
            setVisible(false);
            toast.success(res.data.message, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    } catch(e){
        console.log("ex",e)
    }


  };


  const selected = {
    age: "",
    gender: "",
    name: "",
    parentId: parentId,
    school: "",
    symptoms: "",

  }


  return (
    <div>

      <Modal
        title="Add Child"
        visible={visible}
        onCancel={handleCancel}
        footer={null}

      >
        <Form onFinish={onFinish} initialValues={selected}>
          <Form.Item label="Parent ID" name="parentId">
            <Input disabled/>
          </Form.Item>
          <Form.Item label="Name" name="name" rules={[{ required: true, message: 'name is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item label="Age" name="age" rules={[{ required: true, message: 'Age is required' }]}>
            <Input type="number" />
          </Form.Item>
          <Form.Item label="Gender" name="gender" rules={[{ required: true, message: 'gender is required' }]}>
            <Select>
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
              <Option value="other">Other</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Symptoms" name="symptoms" rules={[{ required: true, message: 'symptoms is required' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item label="School" name="school" rules={[{ required: true, message: 'school is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default AddChild;
