import React, { useEffect, useState } from 'react'
import axios from 'axios';
import Parent from './Parent';
import AppointmentPopup from '../../components/Modal/AppointmentPopup';
import LoginModal from '../../components/Modal/Login';
import { useSelector } from "react-redux";

export default function User() {

    const userSlice = useSelector(item => item.userSlice);
    const { loggedIn } = userSlice;
    const [visible, setVisible] = useState(loggedIn);

    const [data, setData] = useState({})



    const bookOnline = () => {
      setVisible(true)
    }


    useEffect(() => {


        // const decodedName = JSON.parse(localStorage.getItem("token"))?.username
        const decodedName = userSlice.auth.username;


        async function getData() {
            try {

                let url = process.env.REACT_APP_BASE_URL;

                const res = await axios.post(`${url}/parents/fetch-parent`, {
                    name: decodedName
                });

                setData(res.data)
            } catch (e) {
                console.log("exception", e)
            }

        }

        getData()

    }, [])
    //refresh if child array changes


    return (
        <div>
            <h1>User Information</h1>

          
            <Parent data={data} />

        </div>
    )
}
